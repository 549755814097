<template>
  <div class="d-flex flex-column fill-height">
    <div>
      <v-simple-table height="100%" dense fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Unidad
              </th>
              <th class="text-left">
                Velocidad Promedio
              </th>
              <th class="text-left">
                Distancia
              </th>
              <th class="text-left">
                Odometro Inicial
              </th>
              <th class="text-left">
                Odometro Final
              </th>
              <th class="text-left">
                Velocidad Máxima
              </th>
              <th class="text-left">
                Gasolina Gastada
              </th>
              <th>
                <div class="d-flex justify-space-between">
                  <span style="align-self: center">Horas de Motor</span>
                  <v-btn
                    v-if="itemsTable && itemsTable.length"
                    color="success"
                    @click="toExcel"
                    icon
                  >
                    <v-icon>mdi-file-excel</v-icon>
                  </v-btn>
                </div>
              </th>
            </tr>
          </thead>
            <tbody>
              <tr
                style="cursor: pointer;"
                :class="{ active: index === selectedIndex }"
                v-for="(item, index) in itemsTable"
                :key="item.id"
              >
                <td>
                  <v-chip
                    dark
                    label
                    small
                  >
                    {{ item.deviceName }}
                  </v-chip>
                </td>
                <td>{{ item.averageSpeed }}</td>
                <td>{{ item.distance }}</td>
                <td>{{ item.startOdometer }}</td>
                <td>{{ item.endOdometer }}</td>
                <td>{{ item.maxSpeed }}</td>
                <td>{{ item.spentFuel }}</td>
                <td>{{ item.engineHours }}</td>
              </tr>
            </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import _ from 'underscore'
import { mapGetters } from 'vuex'
import Dialog from '@/components/dialog'
import Form from '@/components/form'
import ApiService from '@/util/api.service'
import ExportToExcel from '@/util/json_to_excel.service'
import { periods } from '@/util'

const ol = window.ol

export default {
  name: 'EventsReport',
  props: {
    devices: Array,
    groups: Array,
  },
  data() {
    return {
      itemsTable: [],
      selected: null,
      selectedIndex: null,
      filtro: {
        from: new Date(),
        fromHour: '00:00',
        to: new Date(),
        toHour: '23:59'
      },
      filtersForm: {
        deviceId: {
          label: 'Unidades',
          type: Array,
          options: this.devices,
          multiple: true,
          optional: true
        },
        'deviceId.$': {
          type: String,
          blackbox: true,
        },
        groupId: {
          label: 'Grupos',
          type: Array,
          options: this.groups,
          multiple: true,
          optional: true
        },
        'groupId.$': {
          type: String,
          blackbox: true,
        },
        periodo: {
          label: 'Periodo',
          type: String,
          options: periods,
          onChange: (val, fields) => {
            fields.from_separator.setVisibility(val === 'custom')
            fields.from.setVisibility(val === 'custom')
            fields.fromHour.setVisibility(val === 'custom')
            fields.to_separator.setVisibility(val === 'custom')
            fields.to.setVisibility(val === 'custom')
            fields.toHour.setVisibility(val === 'custom')
          }
        },
        from_separator: {
          label: 'Desde',
          type: String,
          separator: true,
          optional: true
        },
        from: {
          label: 'Fecha',
          type: Date,
          visibility: false
        },
        fromHour: {
          label: 'Hora (HH:mm)',
          type: String,
          visibility: false,
          time: true
        },
        to_separator: {
          label: 'Hasta',
          type: String,
          separator: true,
          optional: true
        },
        to: {
          label: 'Fecha',
          type: Date,
          visibility: false
        },
        toHour: {
          label: 'Hora (HH:mm)',
          type: String,
          visibility: false,
          time: true
        }
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    // keymap() {
    //   return {
    //     up: () => {
    //       if (this.selectedIndex > 0) {
    //         this.selectCoordinate(this.itemsTable[this.selectedIndex - 1])
    //       }
    //     },
    //     down: () => {
    //       if (this.selectedIndex != null && this.selectedIndex + 1 <= this.itemsTable.length) {
    //         this.selectCoordinate(this.itemsTable[this.selectedIndex + 1])
    //       }
    //     }
    //   }
    // }
  },
  mounted() {

  },
  methods: {
    async load() {
      window.VMA.loading(true)
      const data = {
        deviceId: this.filtro.deviceId,
        groupId: this.filtro.groupId,
        ...this.period
      }
      try {
        this.itemsTable = await ApiService({
          url: '/reports/summary',
          method: 'get',
          params: data
        })
        window.VMA.loading(false)
      } catch (e) {
        console.error(e)
        window.VMA.showError({ title: 'Ocurrió un error al cargar los datos' })
      } finally {
        window.VMA.loading(false)
      }
    },
    filtrar() {
      const form = new Form({
        schema: this.filtersForm,
        item: this.filtro
      })
      const dialog = new Dialog({
        title: 'Filtrar rutas',
        actions: [{
          help: 'Filtrar',
          icon: 'mdi-filter',
          color: 'secondary',
          action: async () => {
            if (form.hasErrors()) {
              return
            }
            const item = form.getItem()
            this.filtro = item
            if (item.periodo !== 'custom') {
              this.period = form.fields.periodo.getOption().getPeriod()
            } else {
              this.period = form.fields.periodo.getOption().getPeriod({
                ..._.pick(item, 'from', 'to', 'fromHour', 'toHour')
              })
            }
            this.load()
            dialog.close()
          }
        }]
      })
      dialog.open()
      dialog.append(form)
    },
    toExcel() {
      ExportToExcel(
        'Resumen de unidades '
          + moment(this.period.from).format('DD-MM-YYYY HH mm') + ' a '
          + moment(this.period.to).format('DD-MM-YYYY HH mm'),
        this.itemsTable.map((item) => ({
          Unidad: item.deviceName,
          'Velocidad Promedio': item.averageSpeed,
          Distancia: item.distance,
          'Odometro Inicial': item.startOdometer,
          'Odometro Final': item.endOdometer,
          'Velocidad Máxima': item.maxSpeed,
          'Gasolina Gastada': item.spentFuel,
          'Horas de Motor': item.engineHours
        }))
      )
    },
  }
}

</script>

<style lang="scss">
  .map {
    height: 100%;
    width: 100%;
  }
</style>
